import React from 'react';
import { useTranslation } from 'next-i18next';
import { getCdnUrl } from '@bladebinge/web-service-common/src/utils/get-cdn-url';
import { BROWSING_LINKS } from './constants';
import { HeroCarousel } from './cms-atoms/HeroCarousel';

export const WelcomeHeroCarousel = () => {
    const { t } = useTranslation();

    return (
        <HeroCarousel
            carouselImages={[
                {
                    src: getCdnUrl('/cms/feb2025/vero_lux_drop_2_15_2025_1400_338.jpg'),
                    alt: t('cms:headlines.vero_lux_drop'),
                    link: {
                        url: BROWSING_LINKS.vero_on_bladebinge_shop,
                        text: t('cms:headlines.vero_lux_drop')
                    }
                },
                {
                    src: getCdnUrl('/cms/jan2025/arcane_automaton_available_now_1400_338.jpg'),
                    alt: t('cms:headlines.arcane_drop'),
                    link: {
                        url: BROWSING_LINKS.automaton_on_shop,
                        text: t('cms:headlines.arcane_drop')
                    }
                },
                {
                    src: getCdnUrl('/cms/bladebinge_your_collection_wants_a_new_friend_banner_144_338.jpg'),
                    alt: t('cms:browsing_categories.shop_marketplace'),
                    link: {
                        url: BROWSING_LINKS.marketplace,
                        text: t('cms:browsing_categories.shop_marketplace')
                    }
                }
            ]}
        />
    );
};
